// Module Imports
import styled from "styled-components"

// Custom
import { spacing } from "../designstystem"

export const PageModule = styled.section`
  padding-top: ${spacing[4]};
  padding-bottom: ${spacing[4]};
`
