// React Imports
import React, { useEffect } from "react"
import styled from "styled-components"
//import { Link } from "gatsby"
// Custom Imports
import { Container } from "../elements"
import Logo from "../images/logo-la-rabasse.svg"
import { IconPhone } from "../icons"
import { breakpoints, spacing, defaultColors, colors } from "../designstystem"
import Headroom from "headroom.js"

// Component
const Navigation = ({ className, isFrontPage }) => {
  // Adding Headroom Plugin
  useEffect(() => {
    const headroomOptions = {
      offset: 500,
      tolerance: {
        up: 5,
        down: 0,
      },
    }
    const headroomEL = document.querySelector(".headroom_el")
    new Headroom(headroomEL, headroomOptions).init()
  })

  return (
    <nav className={`headroom_el ${className}`}>
      <Container>
        <ul>
          {/* <li>
          {isFrontPage ? (
            <a href="#about">About</a>
          ) : (
            <Link to="/#about">About</Link>
          )}
        </li> */}
          <li className="logo">
            <a href="/">
              <img src={Logo} alt="La Rabasse Invest" />
            </a>
          </li>
          {/* <li>
          {isFrontPage ? (
            <a href="#contact">Kontakt</a>
          ) : (
            <Link to="/#contact">Kontakt</Link>
          )}
        </li> */}
          <li className="phone">
            <a href="tel:01234233">
              <img src={IconPhone} alt="Call" />
            </a>
          </li>
        </ul>
      </Container>
    </nav>
  )
}

// Styling
export default styled(Navigation)`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${defaultColors.white};
  padding: ${spacing[1]} 0 ${spacing[2]} 0;
  width: 100%;
  z-index: 50;
  border-bottom: 1px solid ${colors.secondary.base};
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    font-style: italic;
    text-transform: uppercase;
    li {
      order: 1;
      img: {
        width: 1em;
        height: 1em;
      }
      a {
        color: ${colors.secondary.dark};
        transition: color 200ms ease-in-out;
        &:hover {
          color: ${colors.secondary.base};
        }
      }
      &.logo {
        order: 0;
        img {
          transition: all 200ms ease-in-out;
          width: 80px;
          height: 85px;
          // Body gets class "scrolled" on scroll > 0 (watch gatsby-browser.js)
          .scrolled & {
            width: 50px;
            height: 60px;
          }
        }
      }
    }
  }

  @media screen and (min-width: ${breakpoints.md}) {
    ul {
      justify-content: space-around;
      li {
        order: 0;
        &.logo {
          img {
            width: 144px;
            height: 172px;
            .scrolled & {
              width: 80px;
              height: 95px;
            }
          }
        }
        &.phone {
          display: none;
        }
      }
    }
  }
`
