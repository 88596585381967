import styled from "styled-components"
import { spacing } from "../designstystem"

export const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding-left: ${spacing[2]};
  padding-right: ${spacing[2]};
`

Container.flex = styled(Container)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
