import React from "react"
import styled from "styled-components"

import { colors } from "../designstystem"

export const Button = ({ href, children, large = false }) =>
  href ? (
    <StyledButton large={large} as="a" href={href}>
      {children}
    </StyledButton>
  ) : (
    <StyledButton large={large}>{children}</StyledButton>
  )

const StyledButton = styled.button`
  background-color: transparent;
  border: 1px solid ${colors.secondary.base};
  border-radius: 0;
  font-family: "GTHaptikMedium", "Helvetica", "serif";
  letter-spacing: 1px;
  color: ${colors.primary.dark};
  cursor: pointer;
  padding: ${props => (props.large ? "1em 4em" : ".5em 1em")};
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;
  &:hover {
    background-color: ${colors.primary.dark};
    border-color: ${colors.primary.dark};
    color: ${colors.secondary.base};
  }
`
