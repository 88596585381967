import React from "react"
import styled from "styled-components"

import { headlineSizes, spacing, colors } from "../designstystem"

export const Headline = ({
  size = headlineSizes.big,
  children,
  counter,
  align = "center",
  marginBottom = spacing[3],
  as = "h2",
}) => (
  <StyledHeadline as={as} size={size} align={align} marginBottom={marginBottom}>
    {counter && (
      <>
        <span className="underlined">{counter}</span>
        <br />
      </>
    )}
    {children}
  </StyledHeadline>
)

const StyledHeadline = styled.h2`
  font-family: "GTHaptikMedium", "Helvetica", "serif";
  letter-spacing: 1px;
  line-height: 1;
  font-size: ${({ size }) => size};
  margin: 0;
  padding: 0;
  text-align: ${({ align }) => align};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  color: ${colors.primary.base};
  .underlined {
    text-decoration: underline;
    font-family: "FoundryFormSerifBook", "Times", "serif";
    display: inline-block;
    margin-bottom: ${spacing[1]};
  }
`
