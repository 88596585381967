// Module Imports
import React, { useState } from "react"
import styled from "styled-components"
import slugify from "slugify"
// Custom Imports
import { spacing, colors } from "../designstystem"
import { slugifySettings } from "../helper"

// Elements
export const FormInput = ({
  inputType = "text",
  title,
  isRequired = false,
  val,
  changeHandler,
  name,
}) => {
  const [isFocused, setFocus] = useState(false)
  const [defaultValue, setDefaultValue] = useState("")
  const inputSlug = name ? name : slugify(title, slugifySettings)

  return (
    <StyledInput className={isFocused ? "focused" : ""}>
      <label htmlFor={inputSlug}>
        {title}
        {isRequired && <span>*</span>}
      </label>

      {inputType === "textarea" ? (
        <textarea
          name={inputSlug}
          id={inputSlug}
          required={isRequired}
          rows="4"
          onFocus={() => setFocus(true)}
          onBlur={({ target }) => {
            if (!target.value) setFocus(false)
          }}
          value={val ? val : defaultValue}
          onChange={
            changeHandler
              ? changeHandler
              : ({ target }) => setDefaultValue(target.value)
          }
        />
      ) : (
        <input
          type={inputType}
          name={inputSlug}
          id={inputSlug}
          required={isRequired}
          onFocus={() => setFocus(true)}
          onBlur={({ target }) => {
            if (!target.value) setFocus(false)
          }}
          value={val ? val : defaultValue}
          onChange={
            changeHandler
              ? changeHandler
              : ({ target }) => setDefaultValue(target.value)
          }
        />
      )}
    </StyledInput>
  )
}

// Styling
const bottomPadding = spacing[0]
const leftPadding = spacing[1]

const StyledInput = styled.div`
  position: relative;
  font-size: 1.5em;
  display: block;
  max-width: 500px;

  // Every Input or Text Field
  input,
  textarea {
    display: block;
    width: 100%;
    padding: ${spacing[3]} ${spacing[2]} ${bottomPadding} ${leftPadding};
    margin: 0;
    background: transparent;
    border-radius: 0;
    border: 0;
    outline: 0;
    border-bottom: 1px solid ${colors.primary.base};
    color: ${colors.primary.base};
    box-shadow: none;
  }

  // The Label
  label {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 2.4em;
    transform: translate(0, -100%);
    left: ${leftPadding};
    line-height: 1;
    color: ${colors.secondary.dark};
    transition: all 200ms ease-in-out;
    cursor: text;
  }

  // Focused State
  &.focused {
    label {
      transform: translate(-20%, -201%) scale(0.6);
    }
  }
`

export default FormInput
