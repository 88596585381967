// Module Imports
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Custom Imports
import { Container } from "../elements"
import { spacing, defaultColors, breakpoints } from "../designstystem"

// Component
const Footer = () => (
  <StyledFooter>
    <Container>
      <ul>
        <li>
          <Link to="/impressum">Impressum</Link>
        </li>
      </ul>
    </Container>
  </StyledFooter>
)

// Styling
const StyledFooter = styled.footer`
  padding: ${spacing[3]} 0;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding-left: ${spacing[2]};
      display: inline-block;
      a {
        color: ${defaultColors.grey};
      }
      &:not(:last-child) {
        padding-right: ${spacing[2]};
      }
    }
  }

  @media screen and (min-width: ${breakpoints.md}) {
    text-align: right;
  }
`
export default Footer
