// React Imports
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

// Custom Imports
import Navigation from "./navigation"

// Image + Icon Imports
import { IconScroll } from "../icons"
import { pattern1 } from "../images/patterns"

// Component
const Header = ({ isFrontPage }) => {
  const { image } = useStaticQuery(headerStaticQuery)

  return (
    <>
      <Navigation isFrontPage={isFrontPage} />
      <StyledHeader fluid={image.childImageSharp.fluid} Tag="header">
        {/* Pattern */}
        <StyledPattern src={pattern1} alt="" />

        {/* Scroll Down */}
        <img
          className="scrollDown"
          aria-hidden="true"
          src={IconScroll}
          alt="Herunterscrollen"
        />
      </StyledHeader>
    </>
  )
}

export const headerStaticQuery = graphql`
  {
    image: file(sourceInstanceName: { eq: "images" }, name: { eq: "signing" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

// Styling
const StyledHeader = styled(BackgroundImage)`
  height: 800px;
  padding-top: 150px;
  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  .scrollDown {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 2.5em;
    height: 2.5em;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  ${Navigation}  {
    position: fixed;
    top: 0;
    left: 0;
  }
`

const StyledPattern = styled.img`
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  min-width: 200px;
  width: 50%;
  overflow: hidden;
  opacity: 0.75;
`

export default Header
